<template>
  <div class="container">
    <div class="header-wrap">
      <div class="header">
        <div class="sub-left">
          <!-- <img class="image" src="@/assets/logo.png" alt="" /> -->
          <!-- <a-divider
          type="vertical"
          style="height: 40px; background-color: #fff; margin: 0 20px"
        ></a-divider> -->
          <span class="store-center">团队管理中心</span>
        </div>
        <div class="sub-right">
          <a-dropdown
            placement="bottomCenter"
            :overlay-style="{ minWidth: '120px' }"
          >
            <div class="ud-admin-header-avatar">
              <a-avatar :src="userInfo.avatar" />
              <span style="color: #fff; margin-left: 5px; cursor: pointer"
                >{{ userInfo.nickname }}&nbsp;</span
              >
              <down-outlined style="color: #fff" />
            </div>
            <template #overlay>
              <a-menu @click="onUserDropClick">
                <a-menu-item key="profile">
                  <div class="ud-cell">
                    <user-outlined />
                    <div class="ud-cell-content">
                      <!-- {{ $t('layout.header.profile') }} -->
                      个人资料
                    </div>
                  </div>
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item key="password">
                  <div class="ud-cell">
                    <key-outlined />
                    <div class="ud-cell-content">
                      {{ $t('layout.header.password') }}
                    </div>
                  </div>
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item key="setting">
                  <div class="ud-cell">
                    <setting-outlined />
                    <div class="ud-cell-content">设置</div>
                  </div>
                </a-menu-item>

                <a-menu-divider />
                <a-menu-item key="logout">
                  <div class="ud-cell">
                    <logout-outlined />
                    <div class="ud-cell-content">
                      {{ $t('layout.header.logout') }}
                    </div>
                  </div>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="center-box">
        <div class="box">
          <div style="margin-top: 30px">
            <span class="title">我的团队({{ corpList.length }}/100)</span
            ><span class="desc">每个团队都为独立运营</span>
          </div>
          <div class="corp-list">
            <div
              class="item"
              :class="
                item.verifyState === '已认证'
                  ? 'border_radius-act'
                  : 'border_radius'
              "
              v-for="(item, index) in corpList"
              :key="index"
              @click="toCorpHome(item.corpId)"
            >
              <div class="sup">
                <div>
                  <span class="corp_name">{{ item.corpName }}</span
                  ><span class="remark" v-if="item.owner">(创始人)</span>
                </div>
                <div class="sign">{{ item.corpType }}</div>
              </div>
              <div class="sub">开通时间: {{ item.createTime }}</div>
            </div>
            <div class="add-box" @click="openEdit()">
              <div>
                <plus-outlined style="color: #1890ff" />
                <span style="color: #1890ff; cursor: pointer">创建团队</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">&copy;2023远丞</div>
    <!-- 修改密码弹窗 -->
    <ud-password v-model:visible="showPassword" />
    <!-- 修改个人信息弹窗 -->
    <ud-profile v-model:visible="showProfile" />
    <!-- 新增团队 -->
    <corp-edit
      v-model:visible="showEdit"
      :data="current"
      @done="reload"
    ></corp-edit>
  </div>
</template>
<script>
import UdPassword from '../../../layout/password.vue'
import UdProfile from './profile.vue'
import corpEdit from '@/views/frm/corp/index-edit.vue'
import { createVNode } from 'vue'
import {
  UserOutlined,
  DownOutlined,
  PlusOutlined,
  KeyOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined,
  SettingOutlined
} from '@ant-design/icons-vue'
import * as corpApi from '@/api/corp/corp.js'
import * as userApi from '@/api/sys/user.js'
export default {
  name: 'AppCenter',
  components: {
    UserOutlined,
    DownOutlined,
    PlusOutlined,
    KeyOutlined,
    LogoutOutlined,
    UdPassword,
    UdProfile,
    SettingOutlined,
    corpEdit
  },
  data() {
    return {
      corpList: [],
      showPassword: false,
      showProfile: false,
      showEdit: false, // 是否显示团队编辑弹出层
      current: null
    }
  },
  mounted() {
    this.getCorpList()
  },
  computed: {
    userInfo() {
      return this.$store.state.user.user
    }
  },
  methods: {
    getCorpList() {
      corpApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.corpList = res.data
            if (this.corpList.length === 1) {
              // this.$router.push({
              //   path: '/app/home',
              //   query: { corpId: this.corpList[0].corpId }
              // })
            }
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 跳转到团队首页
    toCorpHome(id) {
      this.$router.push({ path: '/app/home', query: { corpId: id } })
    },
    /* 个人信息下拉菜单点击 */
    onUserDropClick({ key }) {
      if (key === 'logout') {
        // 退出登录
        this.$confirm({
          title: this.$t('layout.logout.title'),
          content: this.$t('layout.logout.message'),
          icon: createVNode(ExclamationCircleOutlined),
          maskClosable: true,
          onOk: () => {
            // 清除缓存的token
            this.$store.dispatch('user/removeToken').then(() => {
              location.replace('/login') // 这样跳转避免再次登录重复注册动态路由
            })
          }
        })
      } else if (key === 'profile') {
        // this.$router.push('/user/profile')
        this.showProfile = true
      } else if (key === 'password') {
        this.showPassword = true
      }
    },
    openEdit(row) {
      this.showEdit = true
      this.current = row
    },
    reload() {
      this.getCorpList()
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  background: #f7f8fa;
}
.header-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1890ff;
}
.header {
  width: 65%;
  height: 66px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  // padding: 0 50px 0;
  .sub-left {
    height: 100%;
    display: flex;
    align-items: center;
    // margin-left: 285px;
    .image {
      height: 60px;
      width: 60px;
    }
    .store-center {
      font-size: 23px;
      font-weight: bold;
      color: #fff;
      text-shadow: 1px 1px 2px #ffffff86;
    }
  }
}
.content {
  width: 100%;
  flex: 1;
  .center-box {
    display: flex;
    align-items: center;
    justify-content: center;
    .box {
      width: 65%;
    }
  }
  .title {
    font-weight: bold;
    font-size: 20px;
    color: #232529;
  }
  .desc {
    color: #abb2c5;
    font-size: 14px;
    margin-left: 8px;
  }
  .corp-list {
    display: flex;
    align-items: center;
    margin-top: 30px;
    gap: 20px;
    flex-wrap: wrap;
    .border_radius {
      &::before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 100px;
        background: #ebedf5;
        border-radius: 20px 0 0 20px;
        position: absolute;
        left: 0px;
        top: 0;
      }
    }
    .border_radius-act {
      &::before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 100px;
        background: #1890ff;
        border-radius: 20px 0 0 20px;
        position: absolute;
        left: 0px;
        top: 0;
      }
    }
    .add-box {
      width: 400px;
      height: 100px;
      padding: 20px;
      box-sizing: border-box;
      background: #fff;
      border: 1px dashed #1890ff;
      border-radius: 10px 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .item {
      width: 400px;
      height: 100px;
      padding: 20px;
      box-sizing: border-box;
      background: #fff;
      border-radius: 10px 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      cursor: pointer;
      .sup {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .corp_name {
          font-size: 18px;
          font-weight: bold;
          color: #232529;
          margin-right: 5px;
        }
        .sign {
          padding: 0 8px;
          border: 1px solid #1890ff;
          border-radius: 50px;
          color: #1890ff;
          cursor: default;
          flex-shrink: 0;
        }
        .remark {
          color: #aab1c3;
        }
      }
      .sub {
        color: #aab1c3;
      }
    }
  }
}
.footer {
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  text-align: center;
  line-height: 50px;
  color: #8b8d99;
}
</style>
